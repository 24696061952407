<template>
  <div class="xa_cp">
     <div class="dic_inner dic_flex jz">
       <div class="cpbar01">
         <div class="cpbartitle"><a href="#">数字司法</a></div>
         <div class="cpbarcontents dic_flex">
           <div class="cpitems"><a href="#">行政执法</a></div>
           <div class="cpitems"><a href="#">交通执法</a></div>
         </div>
       </div>
       <div class="cpbar01">
         <div class="cpbartitle"><a href="#">数字公安</a></div>
         <div class="cpbarcontents dic_flex">
           <div class="cpitems"><a href="#">治安要素管控</a></div>
           <div class="cpitems"><a href="#">舆情管控</a></div>
           <div class="cpitems"><a href="#">找堵防</a></div>
           <div class="cpitems"><a href="#">流动人口监管</a></div>
         </div>
       </div>
       <div class="cpbar01">
         <div class="cpbartitle"><a href="#">数字电网</a></div>
         <div class="cpbarcontents dic_flex">
           <div class="cpitems"><a href="#">电力营销系统</a></div>
           <div class="cpitems"><a href="#">电能计量系统</a></div>
           <div class="cpitems"><a href="#">自助服务终端</a></div>
           <div class="cpitems"><a href="#">电力三防</a></div>
         </div>
       </div>
     </div>

  </div>
</template>

<script>
  import api from '@/config/api'
  import {
    getRequst,postRequest
  } from '@/api/index'
export default {

  data() {
    return {
       dataInfo:{},
     }
  },
  created() {

  },
  mounted() {
    //this.getInfo()
  },
  methods: {
    //获取列表
    getInfo() {
      let data = {
        asId: 1,
      }
      postRequest(api.aboutUsId, data).then(res => {
         this.dataInfo = res.data
      })

    },
  },

}
</script>

<style lang="less" scoped>
  .xa_cp{
    width:100%;
    // height:100px;
    background: #F4F6FC;
    padding:20px 0;
    padding-top:80px;
    padding-bottom:40px;
  }
  .jz{
    justify-content: center;
  }
  .cpbar01{
    width:200px;
    text-align: center;
    height:44px;
    line-height: 44px;
    border-right: 1px #F7F8FA solid;
  }
  .cpbartitle{
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 8px;
    color: #020814;
    text-align: center;
    background: #E5E6EB;
    color:#272E3B;
  }
  .cpbartitle a{
    position: relative;
  }
 .cpbartitle a:hover {
    color: #175DFF;
}
 .cpbartitle a:hover i {
    font-style: normal;
    transform: translateX(20px);
    transition: all 1s;
    position: absolute;
}
  .cpbartitle i{
    
    font-style: normal;
    transform: translateX(6px);
    transition: all 1s;
    position: absolute;
  }
  .cpbarcontents{
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #41464F;
    display: none;
  }
  .cpitems:after{
    content: ' ';
    width: 1px;
    height: 10px;
    margin-left: 8px;
    margin-right: 8px;
    border-right: 1px solid #41464F;
    opacity: .1;
    display: inline-block;
  }
  .cpitems a:hover{
    border-bottom: 1px solid #175DFF;
    color: #175DFF;
    font-style: normal;
    font-weight: normal;
  }
</style>
