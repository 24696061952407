<template>
  <div class="bodycont">
    <comHeader />
     <div class="contdiv">
      <div class="bgimg">
        <div class="topcont">
        <div class="tcont2">
           <el-carousel height="560px">
                <!-- <el-carousel-item   >
                <div class="swipercont" @click="toDetails1()">
                  <div class="simg">
                     <img src="../../assets/images/b1.png" />
                  </div>
                </div>
                </el-carousel-item> -->
                <el-carousel-item   >
                <div class="swipercont" @click="toDetails1()">
                  <div class="simg">
                     <img src="../../assets/images/b3.png" />
                  </div>
                </div>
                </el-carousel-item>
                 <!-- <el-carousel-item   >
                <div class="swipercont" @click="toDetails1()">
                  <div class="simg">
                     <img src="../../assets/images/b3.png" />
                  </div>
                </div>
                </el-carousel-item> -->
              </el-carousel>
        </div>

      </div>
      </div>
     
    </div>
    <cBar/>
    <cpDetail />
    <bNav />
  </div>

</template>

<script>
  import comHeader from '@/components/header.vue'
  import bNav from '@/components/bottomNav.vue'
 import cBar from '@/components/cp/cpmenu.vue'
  import cpDetail from '@/components/cp/cplist.vue'
  import {
    getRequst,
    postRequest
  } from '@/api/index'
  export default {
    components: {
      comHeader,
      bNav,
      cBar,
      cpDetail
    },
    data() {
      return {
        nodata: require('../../assets/images/infonodata.png'),
        form: {},
        cindex: 8,
        navData: [{
          name: '科技成果',
          url: 'tec',
        }, ],
        typeList: [],
        datainfo: {

        },
        rowinfo: {

        },
        dataList: [],
        dindex1: 0,
        dindex: 0,
        amType: 8,
        amFieldList: '',
        amApplicationList: '',
        amCooperationList: [],
        getAchieveData: [],


        aId: '',
        isshow: false,
        isshow1: false,
        mindex: -1,
        base_url: api.base_url,

        totalCount: 0,
        page: {
          page: 1,
          limit: 10,
          page1: 1,
          limit1: 20,
        },

        totalCount1: 0,
        aName: '',
        isaName: false,

      }
    },
    created() {

    },
    mounted() {
    },
    methods: {
      //获取关键词搜索
      getaName(data) {
        console.log(data, '=========')
        if (this.amType == 0) {
          this.aName = data
          this.getAchieveData = []
          this.page.page1 = 1
          this.getAchieve()
        } else if (this.amType == 8) {
          this.aName = data
          this.dataList = []
          this.page.page = 1
          this.getList1()
        } else {
          this.aName = data
          this.dataList = []
          this.page.page = 1
          this.getList()
        }

      },
      getAchieve() {
        let data = {
          page: this.page.page1,
          limit: this.page.limit1,
        }
        if (this.aName) {
          data.aName = this.aName
        }
        if (this.amFieldList) {
          data.amFieldList = this.amFieldList
        }
        if (this.amApplicationList) {
          data.amApplicationList = this.amApplicationList
        }
        if (this.amCooperationList) {
          data.amCooperationList = this.amCooperationList
        }
        postRequest(api.gethomePage, data).then(res => {
          this.totalCount1 = Number(res.page.totalCount)
          this.getAchieveData = res.page.list
          if (this.getAchieveData.length > 0) {
            this.rowinfo = this.getAchieveData[0]
            console.log(this.rowinfo, '=======')
          }

          if (this.getAchieveData.length == 0) {

            setTimeout(() => {
              this.isshow = true
            }, 1000)
          }
        })
      },
      //获取字典列表
      getTypeList() {
        let data = {
          page: 1,
          limit: 100,
          filterName: '成果类型'
        }
        getRequst(api.sysTypeList, data).then(res => {
          this.typeList = res.page.list || []
          this.typeList.unshift({
            value: '项目成果'
          })
        })
      },
      //获取学科领域
      getType1(data) {
        if (data) {
          if (this.amType == 0) {
            this.amFieldList = data
            this.getAchieveData = []
            this.page.page1 = 1
            this.getAchieve()
          } else {
            this.amFieldList = data
            this.dataList = []
            this.page.page = 1
            this.getList()
          }
        }

      },
      //获取应用领域
      getType2(data) {
        if (data) {
          if (this.amType == 0) {
            this.amFieldList = data
            this.getAchieveData = []
            this.page.page1 = 1
            this.getAchieve()
          } else {
            this.amApplicationList = data
            this.dataList = []
            this.page.page = 1
            this.getList()
          }
        }

      },
      //获取合作方式
      getType3(data) {
        if (data) {
          if (this.amType == 0) {
            this.amCooperationList = data
            this.getAchieveData = []
            this.page.page1 = 1
            this.getAchieve()
          } else {
            this.amCooperationList = data
            this.dataList = []
            this.page.page = 1
            this.getList()
          }
        }

      },

      handleCurrentChange(val) {
        this.page.page = val;
        this.getList()
      },
      handleCurrentChange1(val) {
        this.page.page1 = val;
        this.getAchieve()
      },
      getList1() {
        let data = {
          page: this.page.page,
          limit: this.page.limit,
        }
        if (this.aName) {
          data.aName = this.aName
        }
        postRequest(api.getPageList, data).then(res => {
          if (res.code == 0) {
            this.totalCount = Number(res.page.totalCount)
            this.dataList = res.page.list
            console.log(this.dataList)
            if (res.page.list) {
              if (this.dataList.length > 0) {
                this.datainfo = this.dataList[0]
                this.dataList.forEach(item => {
                  item.filesList.forEach(items => {
                    if (items.msrIsMaster == 1) {
                      item.imgUrl = items.msrUrlStatic
                    } else {
                      item.imgUrl = item.filesList[0].msrUrlStatic
                    }
                  })
                })
              }
            }
            if (this.dataList.length == 0) {
              setTimeout(() => {
                this.isshow1 = true
              }, 500)
            }

          }
        })
      },
      //查询前台成果详情列表
      getList() {
        let data = {
          page: this.page.page,
          limit: this.page.limit,
          amAuditStatue: 4,
        }
        if (!this.amType) {
          data.profileType = ''
        }
        if (this.amType == 0) {
          data.profileType = 1
        }
        if (this.amType == 1 || this.amType == 2 || this.amType == 3 || this.amType == 4 || this.amType == 5 || this
          .amType == 7) {
          data.amType = this.amType
          data.aId = this.aId
          if (data.aId) {
            data.profileType = 1
          } else {
            data.profileType = 2
          }
          data.isReward = ''
        }
        if (this.amType == 1 || this.amType == 2 || this.amType == 3) {
          data.patentType = this.amType
        }
        if (this.amType == 6) {
          data.isReward = 1
          data.aId = this.aId
          if (data.aId) {
            data.profileType = 1
          } else {
            data.profileType = 2
          }
        }

        if (this.amFieldList) {
          data.amFieldList = this.amFieldList
        }
        if (this.amApplicationList) {
          data.amApplicationList = this.amApplicationList
        }
        if (this.amCooperationList) {
          data.amCooperationList = this.amCooperationList
        }
        if (this.aName) {
          data.aName = this.aName
        }

        postRequest(api.getPageList, data).then(res => {
          if (res.code == 0) {
            this.totalCount = Number(res.page.totalCount)
            this.dataList = res.page.list
            if (res.page.list) {
              if (this.dataList.length > 0) {
                this.datainfo = this.dataList[0]
                this.dataList.forEach(item => {
                  item.filesList.forEach(items => {
                    if (items.msrIsMaster == 1) {
                      item.imgUrl = items.msrUrlStatic
                    } else {
                      item.imgUrl = item.filesList[0].msrUrlStatic
                    }
                  })
                })
              }
            }
            if (this.dataList.length == 0) {
              setTimeout(() => {
                this.isshow1 = true
              }, 500)
            }

          }
        })
      },


      //点击查看上一条下一条数据
      //点击查看上一条下一条数据
      getnextData(data, type) {
        if (type == 1) {
          if (this.dindex == 0) {
            this.$message.info('已经是第一条数据了!')
          } else {
            this.dataList.forEach((item, index) => {
              if (item.id == data.id) {
                this.dindex = index - 1
              }
            })
            this.datainfo = this.dataList[this.dindex]
          }
        } else {
          if (this.dataList.length - 1 != this.dindex) {
            this.dataList.forEach((item, index) => {
              if (item.id == data.id && ((index + 1) >= 0)) {
                this.dindex = index + 1
              }
            })
            this.datainfo = this.dataList[this.dindex]
          } else {
            this.$message.info('暂无更多数据!')
          }
        }

      },

      getnext(data, type) {
        if (type == 1) {
          if (this.dindex1 == 0) {
            this.$message.info('已经是第一条数据了!')
          } else {
            this.getAchieveData.forEach((item, index) => {
              if (item.aId == data.aId) {
                this.dindex1 = index - 1
              }
            })
            this.rowinfo = this.getAchieveData[this.dindex1]
          }
        } else {
          if (this.getAchieveData.length - 1 != this.dindex1) {
            this.getAchieveData.forEach((item, index) => {
              if (item.aId == data.aId && ((index + 1) >= 0)) {
                this.dindex1 = index + 1
              }
            })
            this.rowinfo = this.getAchieveData[this.dindex1]
          } else {
            this.$message.info('暂无更多数据!')
          }
        }

      },



      //查看详情
      tourl(data) {
        this.$router.push({
          path: '/resultDetails',
          query: {
            id: data.id,
            url: 'resultsinfo',
            name: '科技成果',
            amType: data.amType,
            isConversion: data.isConversion,
          }
        });

      },
      changindex1(index) {
        this.cindex = index
        this.amType = index
        this.isshow1 = false
        this.dataList = []
        this.page.page = 1
        this.isaName = true
        this.getList1()
      },
      //切换分类筛选
      changindex(index) {
        this.cindex = index
        this.amType = index
        this.amCooperationList = []
        this.isaName = true

        if (this.amType == 0) {
          this.mindex = -1
          this.aId = ''
          this.isshow = false
          this.page.page1 = 1
          this.getAchieveData = []
          this.getAchieve()
        } else {
          this.isshow1 = false
          this.dataList = []
          this.page.page = 1
          this.getList()
        }

      },
      //切换选择数据
      changeData1(item, index) {
        this.rowinfo = item
        this.dindex1 = index
        this.$forceUpdate()
      },
      //切换选择数据
      changeData(item, index) {
        this.datainfo = item
        this.dindex = index
        this.$forceUpdate()
      },
      //选择成果概况数据
      changeDataV(item, index) {
        let data = {
          id: item.aId,
          url: 'resultsinfo',
          name: '科技成果',
        }
        if (this.amType == 0) {
          data.amType = 0
        }
        this.$router.push({
          path: '/resultallDetails',
          query: data
        });
      },



    }
  }
</script>
<style lang="less" scoped>
 .search{
    display: flex;
     justify-content: flex-start; ;
    margin-bottom: 20px;
        width: 1000px;
        margin: 0 auto;
    .demo-input-suffix{
      margin-right: 20px;
    }
  }
  .tcont1 .info {
    /deep/ span {
      line-height: 20px;
    }
  }
  .bodycont {
    width: 100%;
  }

  .contdiv {
    width: 100%;
    margin: 0px auto;
    // padding: 30px 0 0px;
    font-size: 12px;
    // background: url('../../assets/images/site_14.png') no-repeat center -40px #fff;
    //  background: url('../../assets/images/bg1.png') no-repeat center -40px #fff;
    margin-top:-100px;
  }

  .content {
    width: 80%;
    margin: 20px auto;
    font-size: 12px;


  }
  .bgimg{
    width: 100%;

  }
  .topcont {
    display: flex;
    justify-content: space-between;
    width:100%;
    margin:0 auto;



    .tcont2 {
      width: 100%;

      .swipercont {
        width: 100%;
        // margin: 20px auto;
        display: flex;
        flex-wrap: nowrap;

        .simg {
          width: 100%;
          height: 560px;
          overflow: hidden;

          img {
            height: 100%;
            width: auto;
            min-width: 100%;
          }
        }

        .tcont1 {
          width: 30%;
          height: 380px;
          border: 1px solid #ddd;
          overflow: hidden;
          position: relative;
          background: #fff;
          border-radius: 5px;
          text-align: center;
          h2{
            padding: 20px;
            text-align: center;
            font-size: 20px;
          }
          .info{
            font-size: 16px;
            text-align: left;
            padding: 0 10px;
          }

        }

      }
    }

    .tcont3 {
      width: 24%;
      height: 280px;
      border: 1px solid #000;
    }
  }

  .uils_bg {
    background-color: #fff;
    padding: 0 10px;
    overflow: hidden;

    .lists {
      line-height: 33px;
      width: 100%;
      display: inline-block;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      margin-top: 10px;

      .title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        display: inline-block;
        height: 30px;
        line-height: 30px;

        i {
          display: inline-block;
          width: 20px;
          height: 20px;
          text-align: center;
          line-height: 20px;
          border-radius: 50%;
          background-color: #00A0E9;
          color: #fff;
          margin-right: 6px;
        }
      }

      span {
        font-size: 13px;
        color: #000;
      }
    }
  }

  .tnav {
    display: flex;
    justify-content: space-between;
    // background-color: #00428E;
    // color: #fff;
    height: 50px;
    line-height: 50px;
    padding: 0 10px;
    font-size: 16px;
    border-top: 6px solid #00428E;
    color: #00428E;
    border-bottom: 1px solid #ddd;

    .tnavs {
      display: flex;
      justify-content: flex-start;
    }

    span {
      cursor: pointer;
      color: #00428E;

    }
  }

  .countdiv {
    width:1190px;
    margin: 20px auto;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    font-size: 16px;
    padding: 20px 0;
    box-shadow: 0 0 8px #ddd;

    .cborder {
      border-right: 1px solid #000;
    }

    .count {
      flex: 1;

      .num {
        margin-top: 10px;
      }

      .num span {
        font-size: 30px;
        color: #00428E;
        line-height: 30px;
        font-family: "book antiqua";
        margin: 0 10px 0 0;
      }
    }
  }


  .indexThree {
    width: 100%;
    padding: 0px 0px 0px;
    transition: 0.5s;
    position: relative;
    background: #fff;

    .cont1 {
      min-height: 300px;

      .site_e {
        display: flex;
        justify-content: space-around;
        // width: 1190px;
        margin:0px auto;
        flex-wrap: wrap;
        align-items: center;
        .box {
          // width: 250px;
          text-align: center;
          margin: 20px 0 0;
          border: 1px solid #00428E;
          padding: 20px;
          // box-shadow: 0 15px 35px rgb(0 0 0 / 10%);
          border-radius: 0 20px 20px 20px;
          overflow: hidden;
          box-shadow: 0 0px 5px #ddd;

          .icon_img {
            img {
              width: 50px;
              height: 50px;
            }
          }

          .title {
            font-size: 16px;
            color: #000;
            margin-top: 10px;
          }
        }

        .box:hover {
          background-color: #00428E;

          .title {
            color: #fff;
          }
        }
      }
    }
  }

  .cont1 {
    min-height: 300px;

    .titles_ {
      margin: 18px 0 40px;
      text-align: center;
      font-size: 16px;
      letter-spacing: 10px;
      font-weight: bold;
    }

    .site_h2 {
      height: 60px;
      width: 350px;
      margin: 0 auto;
      line-height: 60px;
      text-align: center;


      span {
        font-size: 30px;
        line-height: 60px;
        color: #00A0E9;
        width: 100%;
      }
    }

    .em {
      width: 1180px;
      font-style: normal;
      color: #888;
      height: 2px;
      background: #eee;
      margin: 20px auto;

      .idiv {
        width: 160px;
        background: #00A0E9;
        height: 6px;
        margin: -3px auto 0;
        border-radius: 5px;
      }
    }
  }

  .indexThr {
    width: 100%;
    padding: 30px 0px 40px;
    transition: 0.5s;
    position: relative;
    background: #fafafa;
    background: url('../../assets/images/chengguo.png') no-repeat center -40px #fff;

    .site_e {

      width: 80%;
      margin: 20px auto;
      display: flex;

      .sitetitle {
        display: flex;
        flex-wrap: wrap;
        // background-image: linear-gradient(to bottom, #156bff 0, #05b7f7 100%);
        height: 50px;
        color: #fff;
        position: relative;
        background: #0078ce;
        width: 185px;
        height: 296px;
        padding: 30px 0 0 25px;


        span {

          display: block;
          cursor: pointer;
          height: 50px;
          line-height: 50px;
          color: #fff;
          font-size: 15px;
          text-indent: 24px;
          width: 100%;


        }
      }

      .active {
        background: #fff;

        color: #000 !important;
      }

      .site-c:last-child {
        border: none;
      }

      .sitec {
        display: flex;
        justify-content: flex-start;
      }

      .sitemore {
        position: absolute;
        right: 20px;
        top: 10px;
        height: 24px;
        line-height: 24px;
        padding: 0 10px;
        border: 1px #0078ce solid;
        border-radius: 12px;
        font-size: 14px;
        color: #0078ce;
        cursor: pointer;
      }

      .site-c {
        // display: flex;
        // justify-content: flex-start;
        background-color: #fff;
        padding: 44px 0px 30px;
        width: 980px;
        position: relative;

        .li {
          width: 25%;
          padding: 0 20px;
          border-right: 1px solid #eee;

          img {
            width: 100%;
            height: 140px;
          }
        }

        p {
          height: 30px;
          line-height: 30px;
          overflow: hidden;
          padding-left: 10px;
          font-size: 14px;
          margin: 6px 0;
          cursor: pointer;
          position: relative;

          &:after {
            content: ' ';
            position: absolute;
            left: 0px;
            top: 12px;
            width: 4px;
            height: 4px;
            background-color: #156bff;
            border-radius: 50%;
          }
        }

      }

    }
  }

  .friendLink_07 {
    width: 100%;
    padding: 30px 0px 40px;
    transition: 0.5s;
    position: relative;
    background: #fff;
    background: url('../../assets/images/incase-bg.png') no-repeat center -40px #fff;

    .site_about{
      display: flex;
      width: 1000px;
      margin: 20px auto;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;

      .box {
        box-sizing: border-box;
         width: 200px;
         text-align: center;
         padding: 10px;
         img{
           width: 100%;
           height: 100%;
           height: 50px;
         }

      }
    }

  }



  .inpro-area {
    padding-bottom: 20px;
    position: relative;
    padding-top: 20px;
    background: url(../../assets/images/inpro-bg.jpeg) no-repeat left center;
    background-size: auto 100%;
  }

  .commonweb1400 {
    margin: 0px auto;
    // max-width: 1400px;
    padding: 0 60px;
  }

  .public-title-5 h4 {
    font-size: 42px;
  }

  .public-title-5 span {
    position: relative;
    padding: 5px;
    display: inline-block;
    z-index: 3;
  }

  .public-title-5 h4::after {
    width: 30px;
    height: 30px;
    background-size: 30px auto;
    -webkit-transform: translate(-16px, -8px);
    -ms-transform: translate(-16px, -8px);
    -o-transform: translate(-16px, -8px);
  }

  .public-title-5 h4::after {
    content: '';
    position: absolute;
    -webkit-transform: translate(-13px, -2px);
    -ms-transform: translate(-13px, -2px);
    -o-transform: translate(-13px, -2px);
    transform: translate(-13px, -2px);
    background: url(../../assets/images/ptitle5.png) no-repeat center center;
  }

  .public-title-5 span::before {
    width: 20px;
    height: 20px;
    bottom: -3px;
    left: -20px;
  }

  .public-title-5 span::before {
    content: '';
    position: absolute;
    z-index: -1;
    -webkit-box-shadow: 0px 3px 7px 0px rgb(57 136 234 / 35%);
    box-shadow: 0px 3px 7px 0px rgb(57 136 234 / 35%);
    background-image: -webkit-linear-gradient(162deg, #00e9ff 0%, #00c2f7 29%, #00a2ff 49%, #4166ff 75%, #3401dc 100%);
    background-image: -o-linear-gradient(162deg, #00e9ff 0%, #00c2f7 29%, #00a2ff 49%, #4166ff 75%, #3401dc 100%);
    background-image: linear-gradient(-72deg, #00e9ff 0%, #00c2f7 29%, #00a2ff 49%, #4166ff 75%, #3401dc 100%);
    background-blend-mode: normal;
  }

  .wow {
    text-align: center;
  }

  .incase-tab {
    font-size: 0;
    text-align: center;
    cursor: pointer;
    width: 50%;
    margin: 0 auto;
        display: flex;
        white-space: nowrap;
        justify-content: center;
  }

  .incase-tab li {
    font-size: 16px;
    vertical-align: top;
    display: inline-block;
    color: #333333;
    line-height: 1;
    position: relative;
    border-bottom: solid 1px #d2e5ff;
    width: 20%;
    padding-bottom: 10px;
  }

  .incase-tab li.cur {
    color: #00428E;
    cursor: pointer;
  }

  .incase-tab li.cur::after {
    width: 100%;
  }

  .incase-tab li::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 3px;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    background-image: -webkit-linear-gradient(162deg, #00e9ff 0%, #00c2f7 29%, #00a2ff 49%, #4166ff 75%, #3401dc 100%);
    background-image: -o-linear-gradient(162deg, #00e9ff 0%, #00c2f7 29%, #00a2ff 49%, #4166ff 75%, #3401dc 100%);
    background-image: linear-gradient(-72deg, #00e9ff 0%, #00c2f7 29%, #00a2ff 49%, #4166ff 75%, #3401dc 100%);
    background-blend-mode: normal;
  }

  .indesc {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .site-c:last-child {
    border: none;
  }

  .sitec {
    display: flex;
    // justify-content: flex-start;
    overflow-x: auto;
  }

  .sitemore {
    position: absolute;
    right: 20px;
    top: 10px;
    height: 24px;
    line-height: 24px;
    padding: 0 10px;
    border: 1px #0078ce solid;
    border-radius: 12px;
    font-size: 14px;
    color: #0078ce;
    cursor: pointer;
  }

  .site-c {
    // display: flex;
    // justify-content: flex-start;
    background-color: #fff;
    padding: 44px 0px 30px;
    width: 1180px;
    position: relative;

    .li {
      width: 25%;
      padding: 0 20px;
      border-right: 1px solid #eee;

      img {
        width: 100%;
        height: 140px;
      }
    }

    p {
      height: 30px;
      line-height: 30px;
      overflow: hidden;
      padding-left: 10px;
      font-size: 14px;
      margin: 6px 0;
      cursor: pointer;
      position: relative;

      &:after {
        content: ' ';
        position: absolute;
        left: 0px;
        top: 12px;
        width: 4px;
        height: 4px;
        background-color: #156bff;
        border-radius: 50%;
      }
    }

  }

  .incasecont {
    margin: 30px auto 0;
    text-align: center;
    width:1000px;
    .incaseimg{
      position: relative;
    }
    .incaseinfo {
         margin-top: 10px;
         width: 100%;
         display: block;
         color: inherit;
         font-size: inherit;
         white-space: nowrap;
         text-overflow: ellipsis;
         overflow: hidden;
         color:#00A0E9;
          position: absolute;
          z-index: 2;
          // top: 50%;
          // left: 50%;
          // transform: translate(-50%,-50%);
          font-size: 16px;
    }

    // .swiper-container{
    //   padding: 20px!important;
    //       overflow-x: auto!important;
    // }
  }

  .site_e {
    display: flex;
    justify-content: space-around;
    width: 1000px;
    margin: 20px auto;
    flex-wrap: wrap;
    align-items: center;

    .box {
      width: 240px;
      text-align: center;
      margin: 20px 0 0;
      border: 1px solid #00428E;
      padding: 20px;
      // box-shadow: 0 15px 35px rgb(0 0 0 / 10%);
      // border-radius: 0 20px 20px 20px;
      overflow: hidden;
      box-shadow: 0 0px 5px #ddd;
      border-radius: 10px;

      .icon_img {
        img {
          width: 50px;
          height: 50px;
        }
      }

      .title {
        font-size: 16px;
        color: #000;
        margin-top: 10px;
      }
    }

    .box:hover {
      background-color: #00428E;

      .title {
        color: #fff;
      }
    }
  }

  .h-add-btn {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 30px;
  }

  .public-more2 a {
    width: 155px;
    height: 44px;
    padding-left: 40px;
    line-height: 44px;
    font-size: 16px;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
  }

  .public-more2 a {
    display: inline-block;
    padding: 0 15px;
    background-image: url(../../assets/images/h-index-more-bg.png);
    background-size: 100% 100%;
    background-position: left top;
    color: #00428E;
    position: relative;
  }

  .public-more2 a::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 130px;
    height: 100%;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
  }

  .public-more2 a::after {
    content: '';
    position: absolute;
    top: 50%;
    -webkit-transform: translate(5px, -50%) rotate(-45deg);
    -ms-transform: translate(5px, -50%) rotate(-45deg);
    -o-transform: translate(5px, -50%) rotate(-45deg);
    transform: translate(5px, -50%) rotate(-45deg);
    width: 4px;
    height: 4px;
    border-right: solid 1px #00428E;
    border-bottom: solid 1px #00428E;
  }

  .public-more2 a:hover {
    color: #fff;
    background-image: url(../../assets/images/h-index-more-bg-o.png);
    width: 165px;
  }

  .public-more2 a:hover::after {
    border-color: #fff;
  }

  .public-more2 a:hover::before {
    -webkit-box-shadow: 0px 3px 7px 0px rgba(57, 136, 234, 0.35);
    box-shadow: 0px 3px 7px 0px rgba(57, 136, 234, 0.35);
  }




  .innews {
    padding-top: 25px;
    // padding-bottom: 20px;
    background: url(../../assets/images/innews-bg.jpeg) no-repeat;
  }

  .innews-tab {
    font-size: 0;
    text-align: center;
    margin-top: 15px;
    padding-bottom: 2px;
  }

  .innews-tab li {
    font-size: 14px;
    vertical-align: top;
    display: inline-block;
    color: #333333;
    padding: 0 10px 8px;
    line-height: 1;
    position: relative;
    border-bottom: solid 1px #d2e5ff;
    cursor: pointer;
  }

  .innews-tab li::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 3px;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    background-image: -webkit-linear-gradient(162deg, #00e9ff 0%, #00c2f7 29%, #00a2ff 49%, #4166ff 75%, #3401dc 100%);
    background-image: -o-linear-gradient(162deg, #00e9ff 0%, #00c2f7 29%, #00a2ff 49%, #4166ff 75%, #3401dc 100%);
    background-image: linear-gradient(-72deg, #00e9ff 0%, #00c2f7 29%, #00a2ff 49%, #4166ff 75%, #3401dc 100%);
    background-blend-mode: normal;
  }

  .innews-tab li.cur {
    color: #00428E;
  }

  .innews-tab li.cur::after {
    width: 100%;
  }

  .innews-views {
    margin-top: 20px;
  }

  .innews-views .innews-l img {
    width: 100%;
  }

  .innews-l {
    width: 48%;
    float: left;
  }

  .innews-l .pic {
    height: 374px;
    position: relative;
    overflow: hidden;
  }

  .pic img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
  }

  .word {
    padding: 34px 32px 35px 32px;
    box-shadow: none;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    background: #fff;
    box-sizing: border-box;
  }

  .innews-l h2 {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .innews-views .innews-l h2 {
    line-height: 1;
    color: #080808;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .innews-l .desc {
    color: #666666;
    line-height: 28px;
    max-height: 56px;
    height: 56px;
    display: -webkit-box;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .innews-r {
    width:1000px;
    margin: 0 auto;
  }

  .innews-r li {
    // padding-bottom: 14px;
    // margin-bottom: 14px;
    border-bottom: solid 1px #E6EDFA;
    // padding: 12px 0;
    // margin-left: 15%;
  }

  .innews-info-view {
    // padding: 16px 60px 16px 16px;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    -webkit-border-radius: 2px;
    border-radius: 2px;
  }
  .innewsinfo{
    display: flex;
    justify-content: flex-start;
    margin: 10px 0;
  }

  .innews-info-view .box {
    // display: table;
    // table-layout: fixed;
    // width: 100%;
  }

  .innews-info-view .date {
    width: 70px;
    height: 70px;
     padding-top: 15px;

  }

  .date {
    display: table-cell;
    vertical-align: middle;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    position: relative;
    background: #ECECED;
    color: #666666;
  }


  .date .d {
    font-size: 28px;
    margin-bottom: 6px;
  }

  .date .y_m {
    line-height: 1;
    font-size: 12px;
    text-align: center;
    letter-spacing: -1px;
  }



  .innews-info {
    padding-left: 30px;
    padding-top: 10px;
  }

  .innews-info-view .innews-info {
    padding-left: 30px;
    // display: table-cell;
    vertical-align: middle;
    width: 900px;
    text-align: left;
  }
  .innews-info h2{
    overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
  }
  .innews-info h2 a {
    width: 100%;
    display: block;
    color: inherit;
    font-size: inherit;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .innews-info p {
    line-height: 1;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: 10px;
  }

  .innews {
    // padding-bottom: 60px;
    position: relative;
  }

  .innews::before {
    content: '';
    position: absolute;
    top:16%;
    left:12%;
    width: 90px;
    height: 400px;
    background: url(../../assets/images/innews-bg.png) no-repeat;
    background-size: cover;
  }

  .swiper-slide{
    .img{
      width: 100%;
      height: 300px;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
  .mdiv{
    text-align: left;
    font-size: 16px;
    padding:15px 20px;
  }
  .company{
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
  }
</style>
